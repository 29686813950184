import React, {Component} from 'react';
import {Link} from "react-router-dom";


export class Menu extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
        


    }





    render() {


        // console.log(window.location.search)
        return (
            <div className={'bottom-panel row'}>
                <div className={'row menu'}>

                    <div className={'col-3 menu_item'}>
                        <Link to={"/"+sessionStorage.getItem('widgetId')+"/record"}>
                            <div style={{textAlign: 'center', color: 'gray', textDecoration: 'none'}}>
                                <div style={{width: '30px', margin: '0 auto'}}>
                                    <img src={this.props.active=='record'?'/img/calendar-true.png':'/img/calendarcalendar-off.png'}/>
                                </div>
                                Запись
                            </div>
                        </Link>
                    </div>

                    <div className={'col-3 menu_item'}>
                        <Link to={"/"+sessionStorage.getItem('widgetId')+"/profile"}>
                        <div>
                            <div style={{textAlign: 'center', color: 'gray', textDecoration: 'none'}}>
                                <div style={{width: '30px', margin: '0 auto'}}>
                                    <img src={this.props.active=='profile'?'/img/profile-circle-true.png':'/img/profile-circle-off.png'}/>

                                </div>
                                Профиль
                            </div>
                        </div>
                    </Link>
                    </div>
                    <div className={'col-3 menu_item'} >
                        <Link to={"/"+sessionStorage.getItem('widgetId')+"/about"}>
                        <div>
                            <div style={{textAlign: 'center', color: 'gray', textDecoration: 'none'}}>
                                <div style={{width: '30px', margin: '0 auto'}}>
                                    <img src={this.props.active=='about'?'/img/heart-circle-true.png':'/img/heart-circle-off.png'}/>
                                </div>
                                О нас
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className={'col-3 menu_item'} >
                        <Link to={"/"+sessionStorage.getItem('widgetId')+"/my-record"}>
                        <div>
                            <div style={{textAlign: 'center', color: 'gray', textDecoration: 'none'}}>
                                <div style={{width: '30px', margin: '0 auto'}}>

                                    <img src={this.props.active=='my-record'?'/img/calendar2-true.png':'/img/calendar2-off.png'}/>

                                </div>
                                Мои записи
                            </div>
                        </div>
                        </Link>
                    </div>
                    {/*<div className={'col-2'}><Link to="/all-company"><div>*/}
                    {/*    <div >*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"*/}
                    {/*             className="bi bi-map-fill" viewBox="0 0 16 16">*/}
                    {/*            <path fill-rule="evenodd"*/}
                    {/*                  d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z"/>*/}
                    {/*        </svg></div>*/}
                    {/*    <span>Филиалы</span></div></Link></div>*/}
                </div>
            </div>
        )


    }
}

export default Menu;