import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    Link
} from "react-router-dom";
import List from "../CompanyList/List";
import MenuRecord from "../Record/MenuRecord";
import DateTime from "../Record/DateTime/DateTime";
import Service from "../Record/Service/Service";
import Staff from "../Record/Staff/Staff";
import Menu from "../Menu";
import Security from "../Security";
import axios from "axios";

import GlobalVariable from "../../GlobalVariable";


export class RecordFinal extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: false,
            service: [],
            dateTime: false,
            activeBlock: '',
            activeCompany: [],
            activeDate: [],
            activeTime: [],
            isLoad: false,
            auth: null,
            name: '',
            phone: '',
            record: [],
            star: [],
            sendRecordIsLoad: null,
            companyInfo: [],
            infoRecord: [],

        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }
    updateMenu = (value) => {
        this.setState({activeBlock: value})
    }

    componentDidMount() {
        const data = {};



        axios({

            url: GlobalVariable.host() + '/widget/profile',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    auth: true
                })

            }).catch(error => {
            this.setState({
                auth: false
            })
            // window.location.href = '/' + this.props.widgetId + '/profile/';
            // this.changeError(true)
        })

        axios({

            url: GlobalVariable.host() + '/open/widget/booking/companies/city/info-point/?idPoint=' + this.props.idPoint,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    companyInfo: result.data.points,
                    staff: result.data.infoRecord.staff.name,
                    service: result.data.infoRecord.service,
                    date: result.data.infoRecord.date,

                })


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

    }

    authActions() {
        sessionStorage.setItem('recordSend', 'true');
        sessionStorage.setItem('recordCompanyActive', this.props.idPoint);
        window.location.href = '/' + this.props.widgetId + '/profile/';
    }
    sendRecord() {
        let utm = sessionStorage.getItem('utm');
        this.setState({sendRecordIsLoad: false})
        const data = {
            point: this.props.idPoint,
            utm: utm,
        };

        axios({

            url: GlobalVariable.host() + '/widget/profile/record/create',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({sendRecordIsLoad: true})
                this.setState({})
                window.location.href = '/' + this.props.widgetId + '/my-record';
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })
    }

    renderAuthButton() {
        if (this.state.auth === false) {
            return (
                <div style={{paddingBottom:'100px'}}>
                    <p style={{textAlign:'center'}}>Для продолжения записи Вам необходимо войти под своим номером телефона.</p>
                    <div onClick={() => this.authActions()} className={'button'}>
                        Войти
                    </div>

                </div>
            )
        }
        if (this.state.auth === true) {
            return (<div onClick={() => this.sendRecord()} className={'button'}>
                Записаться
            </div>)
        }
    }

    renderBlock() {
        if (this.state.sendRecordIsLoad == null) {
            console.log(this.state.activeDate)
            return (
                <div className={''} id={'point-item'}>
                    <div className={'top-block'} style={{}}>
                        <img className={'logoPoint'} style={{width: '100%', height: '100%', objectFit: "cover"}}
                             src={'/img/logo_point.png'}
                            // src={this.state.companyInfo.logo}
                             alt=""/>

                        <Link to={"/" + this.props.widgetId + "/record/company-city/" + this.state.companyInfo.cityId}>
                            <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                                <img style={{height: 'fit-content', margin: 'auto 0'}} className="mr-3"
                                     src="/img/arrow-left.png"/>
                                <div style={{marginLeft: '10px', color: 'white'}}>Изменить данные</div>
                            </div>
                        </Link>
                        <div className={'header-block'}>
                            <div>
                                <div style={{justifyContent: 'space-between'}} className={'d-flex mb-2 '}>

                                    <div>Чио Чио {this.state.companyInfo.city}</div>
                                    <div className={'stars'}><img style={{width: '20px'}} className="mr-3 stars-img"
                                                                  src="/img/stars-5.png"/></div>


                                </div>
                                <div style={{cursor: "pointer", marginBottom: '10px'}} className={'city-point'}>
                                    <img className="mr-3" src="/img/pointMap.png"/>
                                    <div style={{fontSize: '15px'}}>{this.state.companyInfo.address}</div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className={'point-block'}>
                        <h3 className={'title_final_record'}>Мастер: <span
                            style={{color: '#E50040'}}>{this.state.staff}</span></h3>
                        <hr/>
                        <h3 className={'title_final_record'}>Дата и время: <span
                            style={{color: '#E50040'}}>{this.state.date}</span></h3>
                        <hr/>

                        <h3 className={'title_final_record'}>Выбрано услуг: {this.state.infoRecord.serviceCount} </h3>
                        {
                            this.state.service.map((serviceItem) => (
                                <div style={{
                                    padding: '5px 10px',
                                    marginTop: '10px',
                                    color: 'white',
                                    width: 'fit-content',
                                    borderRadius: '10px',
                                    backgroundColor: '#E50040'
                                }}>{serviceItem.title}</div>
                            ))
                        }

                    </div>
                    {this.renderAuthButton()}


                    <Menu active={'record'} widgetId={this.props.widgetId}/>
                </div>
            )
        } else if (this.state.sendRecordIsLoad == false) {
            return (
                <div className={''} id={'point-item'}>

                    <div style={{margin: '0 auto', width: 'fit-content'}} className={'p-5 '}>
                        <h3>Происходит отправка данных, ожидайте</h3>
                    </div>


                    <Menu active={'record'} widgetId={this.props.widgetId}/>
                </div>
            )
        } else if (this.state.sendRecordIsLoad == true) {
            return (<div className={''} id={'point-item'}>
                <div className={'top-block'}>
                    <Link to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <img style={{height: 'fit-content', margin: 'auto 0'}} className="mr-3"
                                 src="/img/arrow-left.png"/>
                            <div style={{marginLeft: '10px', color: "white"}}>Запись</div>
                        </div>
                    </Link>
                    <div style={{marginTop: '110px'}} className={'header-block'}>
                        <div>
                            <div style={{justifyContent: 'space-between'}} className={'d-flex mb-2 '}>

                                <div style={{marginLeft: '10px'}}>
                                    <p className={'p-0 m-0'}>{this.state.date}</p>
                                    <p className={'p-0 m-0'}>Адрес: {this.state.companyInfo.address}</p>
                                    <p className={'p-0 m-0'}>Мастер: {this.state.staff}</p>
                                    <p></p>
                                </div>


                            </div>
                            {/*<div style={{cursor: "pointer", marginBottom: '10px'}} className={'city-point'}>*/}
                            {/*    <img className="mr-3" src="/img/pointMap.png"/>*/}
                            {/*    <div style={{fontSize: '15px'}}>Ул. Пушкина д. 123</div>*/}
                            {/*</div>*/}
                        </div>


                    </div>
                </div>
                <div style={{margin: '0 auto', width: 'fit-content'}} className={'p-5 '}>
                    <h3>Поздравляем, Вас успешно записали!</h3>
                </div>

                <Menu active={'record'} widgetId={this.props.widgetId}/>
            </div>)
        }
    }

    render() {

        return (
            this.renderBlock()

        )
    }


}

export default RecordFinal;