import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";

import axios from "axios";

import GlobalVariable from "../../../../GlobalVariable";
import Menu from "../../../Menu";


export class LoginCode extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            isLoad: false,
            auth: false,
            name: '',
            phone: '',
            record: [],

        };
        this.changePhone = this.changePhone.bind(this);
        // this.uploadPhone = this.uploadPhone.bind(this);

    }

    changePhone(event) {
        let val = event.target.value;
        val = val.replace(/[^+\d]/g, '');


        let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

        num = num.trim();

        if (num.length <= 12) {
            this.setState({phone: num});
        }


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }

    uploadPhone() {
        // console.log(this.state.phone)
        const data = {
            phone: "7"+this.state.phone.replace(/[^0-9]/g, "")
        };
        localStorage.setItem('phone', "7"+this.state.phone.replace(/[^0-9]/g, ""))

        axios({

            url: GlobalVariable.host() + '/open/widget/security/check/phone',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                if (result.data.status == 'bad') {
                    this.setState({
                        auth: false,


                    })
                    window.location.href = '/' + this.props.widgetId + '/profile/login/code';
                } else {
                    this.setState({
                        auth: true,
                        // isLoad: true,
                        // name: result.data.name,
                        // phone: result.data.phone,
                        // record: result.data.record,
                    })
                    // localStorage.setItem('phone',this.state.phone);
                    window.location.href = '/' + this.props.widgetId + '/profile/login/code';
                    // return <Redirect to={'/' + this.props.widgetId + '/profile/login/code'}></Redirect>
                }


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })
    }

    // componentDidMount() {
    //     const data = {};
    //
    //     axios({
    //
    //         url: GlobalVariable.host() + '/open/widget/security/check/phone',
    //         headers: {'Content-Type': 'application/json'},
    //         withCredentials: true,
    //         method: "POST",
    //         data: data,
    //         // receive two    parameter endpoint url ,form data
    //     })
    //         .then(result => { // then print response status
    //             this.setState({
    //                 auth: true,
    //                 isLoad: true,
    //                 name: result.data.name,
    //                 phone: result.data.phone,
    //                 record: result.data.record,
    //             })
    //
    //
    //         }).catch(error => {
    //         this.setState({auth: false, isLoad: true})
    //         // this.changeError(true)
    //     })
    // }


    render() {

        return (
            <div id={'login_code'} className={'block-body'} style={{maxWidth: '600px'}}>
                <div className={'header'}>
                    <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile/login'}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Вход с кодом</div>
                        </div>
                    </Link>
                </div>
                <p></p>
                <div className={'form'}>
                    <div className={'input'}>
                        <div className={'img'}><img src="/img/phone-false.png"/></div>
                        <div style={{position:"absolute", padding:'15px', paddingLeft:'50px'}} className={''}>+7</div>
                        <input style={{paddingLeft:'73px'}}  onChange={this.changePhone} value={this.state.phone} placeholder={'(xxx) xxx-xx-xx'}
                               className={'input-text'}/>
                    </div>


                </div>
                <div className={'checkbox'}>

                    <p>Нажимая «войти» вы принимаете Пользовательское соглашение и Политику конфиденциальности</p>
                </div>
                {/*<Link to={'/' + this.props.widgetId + '/profile/login/code'}>*/}
                <div onClick={() => this.uploadPhone()} className={'button'}>
                    Войти
                </div>
                {/*</Link>*/}
                {/*<div className={'info'}>*/}
                {/*    или вы можете войти при помощи Код-пароля*/}
                {/*</div>*/}
                <Menu active={'profile'}/>

            </div>

        )


    }
}

export default LoginCode;