import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.css';
import Global from "./components/Global";
import List from "./components/CompanyList/List";
import Start from "./components/Profile/Start";
import Login from "./components/Profile/Login/Login";
import LoginPassword from "./components/Profile/Login/Password/LoginPassword";
import LoginCode from "./components/Profile/Login/Code/LoginCode";
import CityPoint from "./components/CompanyList/CityPoint";
import PointItem from "./components/CompanyList/PointItem";
import Service from "./components/Record/Service/Service";
import Staff from "./components/Record/Staff/Staff";
import DateTime from "./components/Record/DateTime/DateTime";
import RecordReady from "./components/Record/RecordReady";
import LoginStart from "./components/Profile/Login/LoginStart";
import Registration from "./components/Profile/Login/Registration";
import LoginPhone from "./components/Profile/Login/Code/LoginPhone";
import ChangeProfile from "./components/Profile/Settings/ChangeProfile";
import MyRecord from "./components/MyRecord/MyRecord";
import RecordFinal from "./components/Record/RecordFinal";
import RecordStart from "./components/Record/RecordStart";
import RegistrationCode from "./components/Profile/Registration/RegistrationCode";
import CreateProfile from "./components/Profile/Registration/CreateProfile";
import About from "./components/About/About";
import ChangePassword from "./components/Profile/ChangePassword/ChangePassword";
import PrivacyPolicy from "./components/Profile/PrivacyPolicy/PrivacyPolicy";


const cookies = new Cookies();

ReactDOM.render(
    <Router>

        <Switch>
            {/*<Route path="/" children={<Global />} />*/}
            <Route path="/:widgetId/record/company-all" children={<CompanyAllFunc/>}/>
            <Route path="/:widgetId/record/company-city/:idCity/point/:idPoint/record" children={<RecordReadyFunc/>}/>
            <Route path="/:widgetId/record/company-city/:idCity/point/:idPoint/staff" children={<StaffFunc/>}/>
            <Route path="/:widgetId/record/company-city/:idCity/point/:idPoint/service" children={<ServiceFunc/>}/>
            <Route path="/:widgetId/record/company-city/:idCity/point/:idPoint/date" children={<DateTimeFunc/>}/>
            <Route path="/:widgetId/record/company-city/:idCity/point/:idPoint" children={<PointItemFunc/>}/>
            <Route path="/:widgetId/record/company-city/:idCity" children={<CityPointFunc/>}/>
            <Route path="/:widgetId/record/final/point/:idPoint" children={<RecordFinalFunc/>}/>
            <Route path="/:widgetId/record/company/:idCompany/" children={<CompanyAllFunc/>}/>
            <Route path="/:widgetId/record/" children={<RecordStartFunc/>}/>
            <Route path="/:widgetId/profile/login/code/phone" children={<LoginPhoneFunc/>}/>
            <Route path="/:widgetId/profile/login/code" children={<LoginCodeFunc/>}/>

            <Route path="/:widgetId/profile/login/password" children={<LoginPasswordFunc/>}/>
            <Route path="/:widgetId/profile/login" children={<LoginFunc/>}/>
            <Route path="/:widgetId/profile/registration/create-profile" children={<RegistrationCreateProfileFunc/>}/>
            <Route path="/:widgetId/profile/registration/code" children={<RegistrationCodeFunc/>}/>
            <Route path="/:widgetId/profile/registration" children={<RegistrationFunc/>}/>
            {/*<Route path="/:widgetId/profile/" children={<Login />} />*/}
            <Route path="/:widgetId/profile/change_password" children={<ChangePassword/>}/>
            <Route path="/:widgetId/profile/privacy_policy" children={<PrivacyPolicy/>}/>
            <Route path="/:widgetId/profile/change" children={<ChangeProfileFunc/>}/>
            <Route path="/:widgetId/profile" children={<StartFunc/>}/>
            <Route path="/:widgetId/about" children={<AboutFunc/>}/>
            <Route path="/:widgetId/my-record" children={<MyRecordFunc/>}/>
        </Switch>
    </Router>,
    document.getElementById('root')
);

function CompanyAllFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let {widgetId, idCity} = useParams();


    var params = window
        .location
        .search
        .replace('?','')
        .split('&')
        .reduce(
            function(p,e){
                var a = e.split('=');
                p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                return p;
            },
            {}
        );


    sessionStorage.setItem('widgetId', widgetId)
    return <List widgetId={widgetId} idCity={idCity}/>;
}

function AboutFunc() {

    let {widgetId} = useParams();
    sessionStorage.setItem('widgetId', widgetId)
    return <About widgetId={widgetId}/>;
}

function RecordReadyFunc() {

    let {widgetId, idCity, idPoint} = useParams();
    return <RecordReady widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function RegistrationCreateProfileFunc() {

    let {widgetId, idCity, idPoint} = useParams();
    sessionStorage.setItem('widgetId', widgetId)
    return <CreateProfile widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function RecordFinalFunc() {
    let {widgetId, idCity, idPoint} = useParams();
    return <RecordFinal widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function ChangeProfileFunc() {
    let {widgetId, idCity, idPoint} = useParams();
    return <ChangeProfile widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function RecordStartFunc() {

    let {widgetId, idCity, idPoint} = useParams();
    sessionStorage.setItem('widgetId', widgetId)
    return <RecordStart widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function RegistrationCodeFunc() {
    let {widgetId, idCity, idPoint} = useParams();
    return <RegistrationCode widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function MyRecordFunc() {
    let {widgetId, idCity, idPoint} = useParams();
    return <MyRecord widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function StaffFunc() {
    let {widgetId, idCity, idPoint} = useParams();

    return <Staff widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function ServiceFunc() {
    let {widgetId, idCity, idPoint} = useParams();

    return <Service widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function DateTimeFunc() {
    let {widgetId, idCity, idPoint} = useParams();
    return <DateTime widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}

function CityPointFunc() {
    let {widgetId, idCity} = useParams();
    sessionStorage.setItem('widgetId', widgetId)
    return <CityPoint widgetId={widgetId} idCity={idCity}/>;
}

function LoginPhoneFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {widgetId} = useParams();

    return <LoginPhone widgetId={widgetId}/>;
}

function LoginCodeFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {widgetId} = useParams();

    return <LoginCode widgetId={widgetId}/>;
}

function LoginPasswordFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {widgetId} = useParams();

    return <LoginPassword widgetId={widgetId}/>;
}

function LoginFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {widgetId} = useParams();

    return <Login widgetId={widgetId}/>;
}

function RegistrationFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {widgetId} = useParams();

    return <Registration widgetId={widgetId}/>;
}

function StartFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {widgetId} = useParams();

    return <Start widgetId={widgetId}/>;
}

function PointItemFunc() {
    let {widgetId, idCity, idPoint} = useParams();

    return <PointItem widgetId={widgetId} idCity={idCity} idPoint={idPoint}/>;
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
