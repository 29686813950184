import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Menu from "../../Menu";
import axios from "axios";
import GlobalVariable from "../../../GlobalVariable";


export class ChangePassword extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            date: '',
            email: '',
            password1: '',
            password2: '',
            code: '',
            code1: '',
            code2: '',
            code3: '',
            code4: '',
            passwordCheck: false,
            resultChangePassword: null,

        };

        this.changeCode = this.changeCode.bind(this);
        this.changeCode1 = this.changeCode1.bind(this);
        this.changeCode2 = this.changeCode2.bind(this);
        this.changeCode3 = this.changeCode3.bind(this);
        this.changeCode4 = this.changeCode4.bind(this);
        this.ChangePassword1 = this.ChangePassword1.bind(this);
        this.ChangePassword2 = this.ChangePassword2.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    renderButton() {


        if (this.state.password1 == this.state.password2 && this.state.password2.length > 2) {
            return (

                <div onClick={() => this.uploadCode()} className={'button'}>
                    Сменить пароль
                </div>

            )
        } else {
            return (

                <div className={'button-false'}>
                    Сменить пароль
                </div>

            )
        }
    }
    renderButton2() {


        let code = this.state.code1.concat(this.state.code2, '', this.state.code3, '', this.state.code4)
        if (code.length==4) {
            return (

                <div onClick={() => this.changePassword()} className={'button'}>
                    Сменить пароль
                </div>

            )
        } else {
            return (

                <div className={'button-false'}>
                    Сменить пароль
                </div>

            )
        }
    }
    changeCode(event) {
        this.setState({code: event.target.value});
    }

    ChangePassword1(event) {
        this.setState({password1: event.target.value});
    }

    ChangePassword2(event) {
        this.setState({password2: event.target.value});
    }

    changeCode1(event) {

        this.setState({code1: event.target.value});
    }

    changeCode2(event) {
        this.setState({code2: event.target.value});
    }

    changeCode3(event) {
        this.setState({code3: event.target.value});
    }

    changeCode4(event) {
        this.setState({code4: event.target.value});
    }


    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }

    uploadCode() {
        // console.log(this.state)
        this.setState({passwordCheck: true})
        const data = {};

        axios({

            url: GlobalVariable.host() + '/widget/security/change_password/check/code',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    auth: true,
                    resultChangePassword: true,
                })


            }).catch(error => {
            this.setState({resultChangePassword: false})
            // this.changeError(true)
        })
    }

    changePassword() {
        // console.log(this.state)
        let code = this.state.code1.concat(this.state.code2, '', this.state.code3, '', this.state.code4)
        if (code.length==4) {


            const data = {
                code: code,
                password1: this.state.password1,
                password2: this.state.password2,

            };

            axios({

                url: GlobalVariable.host() + '/widget/security/password/change',
                headers: {'Content-Type': 'application/json'},
                withCredentials: true,
                method: "POST",
                data: data,
                // receive two    parameter endpoint url ,form data
            })
                .then(result => { // then print response status

                    this.setState({
                        auth: true,
                        resultChangePassword: true,
                    })


                }).catch(error => {
                this.setState({resultChangePassword: false})
                // this.changeError(true)
            })
        }
    }

    renderBlock() {
        //
        return (
            <div>
                <div style={this.state.passwordCheck ? {display: 'block'} : {display: 'none'}}>
                    <div className={'form'}>

                        <div>
                            <form className={'input-code-block'}>

                                <input onKeyUp={this.changeCode1} style={{}} type={'text'} name={'inp'} data-start={'0'}
                                       data-len="1" size={1} placeholder={'X'}
                                       className={'input-code'}/>
                                <input onKeyUp={this.changeCode2} style={{}} type={'text'} name={'inp'} data-start={'1'}
                                       data-len="1" size={1} placeholder={'X'}
                                       className={'input-code'}/>
                                <input onKeyUp={this.changeCode3} style={{}} type={'text'} name={'inp'} data-start={'2'}
                                       data-len="1" size={1} placeholder={'X'}
                                       className={'input-code'}/>
                                <input onKeyUp={this.changeCode4} style={{}} type={'text'} name={'inp'} data-start={'3'}
                                       data-len="1" size={1} placeholder={'X'}
                                       className={'input-code'}/>
                            </form>

                        </div>


                    </div>

                    <div className={'mt-5 mb-5'}>
                        Для смены пароля, нажмите "Отправить код", далее введите последние 4 цифры номера, с которого
                        поступил звонок
                    </div>
                    {this.renderButton2()}
                </div>
                <div style={this.state.passwordCheck ? {display: 'none'} : {display: 'block'}}>
                    <div className={'form'}>

                        <div className={'input'}>
                            <div className={'img'}><img
                                src={this.state.password1.length > 3 ? '/img/unlock-active.png' : '/img/unlock-false.png'}/>
                            </div>
                            <input value={this.state.password1} onChange={this.ChangePassword1} placeholder={'Пароль'}
                                   className={'input-text'}/>
                        </div>
                        <div className={'input'}>
                            <div className={'img'}><img
                                src={this.state.password2.length > 3 ? '/img/unlock-active.png' : '/img/unlock-false.png'}/>
                            </div>
                            <input value={this.state.password2} onChange={this.ChangePassword2}
                                   placeholder={'Повторите пароль'}
                                   className={'input-text'}/>
                        </div>


                    </div>
                    {this.renderButton()}
                </div>
            </div>
        )


    }

    render() {


        return (
            <div className={'block-body'}>
                <div className={'header'}>
                    <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile'}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Смена пароля</div>
                        </div>
                    </Link>
                </div>
                {this.renderBlock()}


                <Menu active={'profile'} widgetId={this.props.widgetId}/>
            </div>


        )


    }
}

export default ChangePassword;