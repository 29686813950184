import React, {Component} from 'react';
import GlobalVariable from "../../../GlobalVariable";
import Menu from "../../Menu";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";

export class Staff extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staffs: [],
            activeStaff: [],
            staffsisLoad: true,
        };


    }

    chooseStaff(Staff) {
        let id = 1;
        if (Staff.id == 0) {

            id = 1;
        } else if (Staff) {
            id = Staff.id;
        }
        let urlAPI = GlobalVariable.host() + '/open/widget/booking/companies/staffchange';
        const data = {
         id:id

        };
        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true

        })
            .then(result => { // then print response status

                window.location.href = "/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint;


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })


    }

    componentDidMount() {



        let urlAPI = GlobalVariable.host() + '/open/widget/booking/companies/resources';


        const data = {


        };

        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                console.log(result.data)
                this.setState({
                    staffs: result.data.resources,
                    activeStaff: result.data.idStaff,
                    staffsisLoad: false
                })


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

    }

    renderStaff() {
        let staffs = this.state.companies;
        if (this.state.staffsisLoad) {
            return (
                <div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>


                </div>
            )
            // } else if (staffs.size == 0) {
            //     return (
            //         <div>Не найдено</div>
            //     )
        } else {

            return (

                this.state.staffs.map(items => (
                    <div onClick={() => this.chooseStaff(items)} className={'col-12 row'} style={{
                        cursor: "pointer",
                        marginTop: '15px',
                        marginBottom: '15px'
                    }}>


                        <div className={'col-3'} style={{
                            width: '80px',
                            height: '80px',
                            backgroundColor: '#E50040',
                            borderRadius: '300px',
                        }}>
                            <div
                                style={{
                                    color: 'white',
                                    fontSize: "50px",
                                    textAlign: 'center'
                                }}
                            >{items.title[0]}</div>
                        </div>
                        <div className={'col-8'}>
                            <h5>{items.title}</h5>
                            {/*<div>Топ-мастер</div>*/}
                            <div style={{width: '100px', height: '30px'}} className={'stars'}><img
                                style={{width: '100px'}} className="mr-3 stars-img" src="/img/stars-5.png"/></div>
                            <h6>{items.description}</h6>

                        </div>
                        <div className={'col-1'} style={{marginRight: '20px', margin: "auto"}}>


                            <img className=""
                                 src={this.state.activeStaff.id == items.id ? "/img/Ellipse-true.png" : "/img/EllipseFalse.png"}/>
                        </div>

                        <div style={{marginTop: '10px'}} className={'col-12'}>


                            <div className={'row date_block'}>


                            </div>

                        </div>


                    </div>


                ))

            )
        }
    }

    render() {
        let staffs = this.state.staffs
        return (
            <div id={'staff-block'} className={'block-body'}>
                <div className={'header'}>
                    <Link
                        to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint + ""}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Мастер</div>
                        </div>
                    </Link>
                </div>


                <div style={{paddingBottom:'100px'}} className={'body_block'}>
                    <div style={{padding: '10px'}} className={'row'}>
                        {/*<div className={'button-chio'} onClick={() => this.chooseStaff(0)}*/}
                        {/*     style={{*/}
                        {/*         textAlign: "center",*/}
                        {/*         fontSize: '15px',*/}
                        {/*         cursor: "pointer",*/}
                        {/*         marginBottom: '50px',*/}
                        {/*     }}>Пропустить выбор*/}
                        {/*    сотрудника*/}
                        {/*</div>*/}
                        {this.renderStaff()}




                    </div>

                </div>

                <Menu active={'record'}/>
            </div>
        )


    }
}

export default Staff;