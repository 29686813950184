import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import List from "../CompanyList/List";
import MenuRecord from "../Record/MenuRecord";
import DateTime from "../Record/DateTime/DateTime";
import Service from "../Record/Service/Service";
import Staff from "../Record/Staff/Staff";
import Menu from "../Menu";
import Security from "../Security";
import axios from "axios";

import GlobalVariable from "../../GlobalVariable";


export class RecordStart extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            idPoint: '',
            idCity: '',


        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }
    updateMenu = (value) => {
        this.setState({activeBlock: value})
    }

    componentDidMount() {


        let idCity = localStorage.getItem('activeCompanyCity')
        let idPoint = localStorage.getItem('activePoint')
        if (idPoint) {
            window.location.href = '/'+this.props.widgetId+'/record/company-city/' + idCity + '/point/' + idPoint;
        }

        else if (idCity) {
            window.location.href = '/'+this.props.widgetId+'/record/company-city/' + idCity;

        }
        else {
            window.location.href = '/'+this.props.widgetId+'/record/company-all/'
        }



//
//         let staff = localStorage.getItem('staff');
//         let service = localStorage.getItem('service');
//         // let staff = localStorage.getItem('staff');
//         if (service != null) {
// console.log(JSON.parse(service) )
//             this.setState({service: JSON.parse(service)})
//         }
//         if (staff != null) {
//
//             this.setState({staff: JSON.parse(staff)})
//         }
    }

    render() {


        return (
            <div></div>

        )


    }
}

export default RecordStart;