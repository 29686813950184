import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Menu from "../../Menu";




export class CreateProfile extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            name:'',
            phone:'',
            date:'',
            email:'',


        };
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    renderButton() {
        if (this.state.email.length > 3 ) {
            return (

                    <div className={'button'}>
                        Сохранить
                    </div>

            )
        } else {
            return (

                    <div className={'button-false'}>
                        Сохранить
                    </div>

            )
        }
    }


    render() {

        return (
            <div className={'block-body'}>
                <div className={'header'}>
                    <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile'}>
                    <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                        <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                        <div className={'title'}>Заполните профиль</div>
                    </div>
                    </Link>
                </div>
                <div className={'form'}>
                    <div className={'input'}>
                        <div className={'img'}><img src="/img/profile-logo.png" /></div>
                        <input placeholder={'Ваше имя'}  value={this.state.name} className={'input-text'}/>
                    </div>
                    <div className={'input'}>
                        <div className={'img'}><img src="/img/calendar.png" /></div>
                        <input name="date"
                               onChange={this.handleInputChange}  placeholder={'xx.xx.xxxx'} value={this.state.date} className={'input-text'}/>
                    </div>
                    <div className={'input'}>
                        <div className={'img'}><img src="/img/call.png" /></div>
                        <input name="phone"
                               onChange={this.handleInputChange} value={this.state.phone} placeholder={'+7 (xxx) xxx-xx-xx'} className={'input-text'} disabled={true}/>
                    </div>
                    <div className={'input'}>
                        <div className={'img'}><img src="/img/sms.png" /></div>
                        <input placeholder={'Ваш email'} value={this.state.email} className={'input-text'}/>
                    </div>
                    <div className={'input'}>
                        <div className={'img'}><img src="/img/location.svg" /></div>
                        <input value={'Россия'} className={'input-text'}/>
                    </div>

                </div>
                {this.renderButton()}

                <Menu active={'profile'} widgetId={this.props.widgetId}/>
            </div>


        )


    }
}

export default CreateProfile;