import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import List from "../CompanyList/List";
import MenuRecord from "../Record/MenuRecord";
import DateTime from "../Record/DateTime/DateTime";
import Service from "../Record/Service/Service";
import Staff from "../Record/Staff/Staff";
import Menu from "../Menu";
import Security from "../Security";
import axios from "axios";
import InfoProfile from "./InfoProfile";
import GlobalVariable from "../../GlobalVariable";


export class Start extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            isLoad: false,
            auth: false,
            exitModal: false,
            name: '',
            phone: '',
            record: [],
            info: [],

        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }


    componentDidMount() {
        const data = {};
        let recordSend = sessionStorage.getItem('recordSend')
        let recordCompanyActive = sessionStorage.getItem('recordCompanyActive')
        // console.log(recordSend)
        axios({

            url: GlobalVariable.host() + '/widget/profile',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                // if (result.data.status == 'not-full') {
                //     // window.location.href = '/' + this.props.widgetId + '/profile/change';
                // } else {
                    this.setState({
                        auth: true,
                        isLoad: true,
                        name: result.data.name,
                        phone: '+' + result.data.phone,
                        record: result.data.record,
                        info: result.data
                        ,
                    })
                // console.log(recordSend)
                if(recordSend == 'true'){

                    sessionStorage.removeItem('recordSend');
                    sessionStorage.removeItem('recordCompanyActive');
                    window.location.href = '/' + this.props.widgetId + '/record/final/point/'+recordCompanyActive;
                }
                // }


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })
    }

    exit() {
        const data = {};

        axios({

            url: GlobalVariable.host() + '/open/widget/security/exit',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                window.location.href = '/' + this.props.widgetId + '/profile';


            }).catch(error => {

            // this.changeError(true)
        })
    }

    renderExit(){
        if(this.state.exitModal){
            return(
                <div className={'modal-exit'}>
                    <div className={'exit-block'}>
                        <div className={'title'}>Выход</div>
                        <div className={'text'}>Вы уверены, что хотите выйти?</div>
                        <div  className={'d-flex buttons'}>
                            <div onClick={() => this.setState({exitModal:false})} style={{cursor:'pointer'}}  className={'button-false mr-2'}>
                                Отмена
                            </div>
                            <div onClick={()=>this.exit()}  style={{cursor:'pointer'}} className={'button ml-2'}>
                                Выйти
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }
    render() {
        if (this.state.isLoad) {

            if (this.state.auth) {
                return (
                    <div  className={'block-body'}>
                        <div className={'header'}>
                            <Link to={''}>
                                <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                                    {/*<div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>*/}

                                    <div className={'title'}>Профиль</div>
                                </div>
                            </Link>
                        </div>
                        <div className={'info d-flex'}>
                            <div className={'logo'} style={{paddingTop:'20px'}}>
                                <div className={''} style={{
                                    width: '100px',
                                    height: '100px',
                                    backgroundColor: '#E50040',
                                    borderRadius: '300px',
                                    margin:'0 auto'
                                }}>

                                    <div
                                        style={{
                                            color:'white',
                                            fontSize:"60px",
                                            textAlign:'center',

                                        }}
                                    >{this.state.name[0]}</div>
                                </div>
                            </div>
                            <div className={'info-user'}>
                                <p className={'name'}>{this.state.name}</p>
                                <p className={'text'}>{this.state.phone}</p>
                                <p className={'text'}>Бесплатых стрижек: {this.state.info.scissorsTotal}</p>
                            </div>

                        </div>
                        <div className={'nav'}>
                            <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile/change'}>
                                <div className={'link'}>
                                    <div className={'img'}><img src="/img/profile-logo.png"/></div>
                                    Редактировать профиль
                                </div>
                            </Link>
                            <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile/privacy_policy'}>
                                <div className={'link'}>
                                    <div className={'img'}><img src="/img/politic.png"/></div>
                                    Политика конфиденциальности
                                </div>
                            </Link>
                            <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile/change_password'}>
                                <div className={'link'}>
                                    <div className={'img'}><img src="/img/lock.png"/></div>
                                    Пароль
                                </div>
                            </Link>
                            <div  onClick={() => this.setState({exitModal:true})} style={{color: '#e40136', cursor:'pointer'}} className={'link'}>
                                <div className={'img'}><img src="/img/logout.png"/></div>
                                Выход
                            </div>

                        </div>
                        <p className={'mt-5'}>Для получения уведомлений о записях подпишитесь на телеграмм бот <a className={'linkActive'}  target={'_blank'} href={'https://t.me/Chio_Chio_official_bot'}>https://t.me/Chio_Chio_official_bot</a></p>
                        {this.state.exitModal? <div className={'background-black-opacity'}></div>: ""}

                        {this.renderExit()}
                        <Menu active={'profile'} widgetId={this.props.widgetId}/>
                    </div>


                )
            } else {
                return (
                    <div id={'start-profile'}>
                        <div className={'logo_block'}>
                            <div className={'logo'}>

                            </div>

                        </div>
                        <div className={'header'}>
                            <h2>Рады видеть вас!
                            </h2>
                            <h2>Авторизуйтесь</h2>

                        </div>
                        <div style={{paddingBottom:'100px'}} className={'bottom'}>
                            <Link to={'/'+this.props.widgetId+'/profile/login/code/phone'}>
                                <div style={{width: '100%'}} className={'button mb-2'}>
                                    Войти
                                </div>
                            </Link>
                            {/*<div>или</div>*/}
                            {/*<Link to={'/' + this.props.widgetId + '/profile/registration'}>*/}
                            {/*    <div style={{width: '100%'}} className={'button-false mt-2'}>*/}
                            {/*        Зарегистрироваться*/}
                            {/*    </div>*/}
                            {/*</Link>*/}

                        </div>

                        <Menu active={'profile'}/>
                    </div>

                )
            }
        } else {
            return (
                <div id={'start-profile'}>
                    <div className={'logo_block'}>
                        <div className={'logo'}>

                        </div>

                    </div>
                    <div className={'header'}>
                        <h2>Рады видеть вас!
                        </h2>
                        <h2>Загружаем данные, пожалуйста подождите</h2>

                    </div>


                    <Menu active={'profile'}/>
                </div>
            )
        }


    }
}

export default Start;