import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import axios from "axios";

import GlobalVariable from "../../../../GlobalVariable";
import Menu from "../../../Menu";


export class LoginPassword extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            password: '',
            phone: '',


        };

        this.ChangePhone = this.ChangePhone.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);
    }

    ChangePhone(event) {
        let val = event.target.value;
        val = val.replace(/[^+\d]/g, '');
        // console.log(val);

        let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

        num = num.trim();

        if (num.length <= 12) {
            this.setState({phone: num});
        }

    }

    ChangePassword(event) {
        this.setState({password: event.target.value});
    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }


    login() {
        const data = {
            username: "7" + this.state.phone.replace(/[^+\d]/g, ''),
            password: this.state.password,
        };

        axios({

            url: GlobalVariable.host() + '/widget1/login_check',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                window.location.href = '/' + this.props.widgetId + '/profile';


            }).catch(error => {
            this.setState({auth: false, isLoad: true, error: true})
            // this.changeError(true)
        })
    }

    renderButton() {
        if (this.state.password.length > 2) {
            return (
                <div onClick={() => this.login()} className={'button'}>
                    Войти
                </div>
            )
        } else {
            return (
                <div className={'button-false'}>
                    Войти
                </div>
            )
        }
    }

    renderError() {
        if (this.state.error == null) {
            return (
                ''
            )
        } else if (this.state.error == false) {
            return (
                <div style={{
                    backgroundColor: 'whitesmoke',
                    borderColor: 'whitesmoke',
                    width: '250px',
                    position: 'absolute',
                    bottom: '65px',
                    right: '20px',
                    color: "#0D263F"
                }} className="alert alert-success alert-dismissible fade show" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Закрыть">
                        <span aria-hidden="true">&times;</span></button>
                    Данные сохранены
                </div>
            )
        } else if (this.state.error == true) {
            return (
                <div style={{
                    backgroundColor: 'whitesmoke',
                    borderColor: 'whitesmoke',
                    width: '250px',
                    position: 'absolute',
                    bottom: '65px',
                    right: '20px',
                    color: "#0D263F"
                }} className="alert alert-success alert-dismissible fade show" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Закрыть">
                        <span aria-hidden="true">&times;</span></button>
                    Ошибка входа, не правильный телефон или пароль. Для первого входа войдите, через одноразовый код.
                </div>
            )
        }

    }

    render() {

        return (
            <div className={'block-body'} style={{maxWidth: '600px', margin: '0 auto'}}>
                <div className={'header'}>
                    <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile'}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Вход с паролем</div>
                        </div>
                    </Link>
                </div>
                <div className={'form'}>
                    <div className={'input'}>
                        <div className={'img'}><img
                            src={this.state.phone.length > 9 ? '/img/phone-active.png' : '/img/phone-false.png'}/></div>
                        <div style={{position: "absolute", padding: '15px', paddingLeft: '50px'}} className={''}>+7
                        </div>
                        <input style={{paddingLeft: '73px'}} onChange={this.ChangePhone} value={this.state.phone}
                               placeholder={'(xxx) xxx-xx-xx'}
                               className={'input-text'}/>
                    </div>
                    <div className={'input'}>
                        <div className={'img'}><img
                            src={this.state.password.length > 3 ? '/img/unlock-active.png' : '/img/unlock-false.png'}/>
                        </div>
                        <input value={this.state.password} onChange={this.ChangePassword} placeholder={'Пароль'}
                               className={'input-text'}/>
                    </div>


                </div>
                {this.renderButton()}
                {this.renderError()}

                <Menu active={'profile'} widgetId={this.props.widgetId}/>
            </div>


        )


    }
}

export default LoginPassword;