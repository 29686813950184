import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import axios from "axios";

import GlobalVariable from "../../../GlobalVariable";
import Menu from "../../Menu";


export class Login extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            isLoad: false,
            auth: false,
            name: '',
            phone: '',
            record: [],

        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }


    componentDidMount() {
        const data = {};

        axios({

            url: GlobalVariable.host() + '/widget/profile',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    auth: true,
                    isLoad: true,
                    name: result.data.name,
                    phone: result.data.phone,
                    record: result.data.record,
                })


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })
    }


    render() {

        return (
            <div id={'login'} className={'block-body'} style={{maxWidth:'600px', margin:'0 auto'}}>
                <div className={'header'}>
                    <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile'}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Выберите удобный для Вас способ входа</div>
                        </div>
                    </Link>
                </div>

                <Link  to={'/'+this.props.widgetId+'/profile/login/code/phone'}>
                    <div className={'button'}>
                        Вход по одноразовому коду из входящего звонка
                    </div>
                </Link>
                <Link style={{paddingBottom:'100px'}}  to={'/'+this.props.widgetId+'/profile/login/password'}>
                    <div className={'button'}>
                        Вход по паролю
                    </div>
                </Link>
                <Menu active={'profile'} widgetId={this.props.widgetId}/>
            </div>
            // <div id={'login'}>
            //     <div className={'header_block'}>
            //         <h3>Выберите удобный для Вас способ входа</h3>
            //
            //     </div>
            //
            //
            //
            //
            // </div>

        )


    }
}

export default Login;