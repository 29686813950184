import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import axios from "axios";

import GlobalVariable from "../../../GlobalVariable";
import Menu from "../../Menu";


export class Registration extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            password: '',
            phone: '',
            name: '',
            isGoing: false,
            error: ''
        };

        this.ChangeName = this.ChangeName.bind(this);
        this.ChangePhone = this.ChangePhone.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    ChangeName(event) {
        sessionStorage.setItem('regName', event.target.value)
        this.setState({name: event.target.value});
    }

    ChangePhone(event) {
        let val = event.target.value;
        val = val.replace(/[^+\d]/g, '');
        // console.log(val);

        let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

        num = num.trim();

        if (num.length <= 12) {
            this.setState({phone: num});
        }
        sessionStorage.setItem('regPhone', "7"+num.replace(/[^+\d]/g, ''))

    }

    ChangePassword(event) {

        this.setState({password: event.target.value});
    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    createClientApi() {
        const data = {
            phone: this.state.phone.replace(/[^0-9]/g, ""),
            name: this.state.name,
            password: this.state.password,
        };

        axios({

            url: GlobalVariable.host() + '/open/widget/security/client/add',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                if (result.data.status == 'bad') {
                    this.setState({
                        error: 'Пользователь существует'
                    })
                }else {
                    window.location.href = '/' + this.props.widgetId + '/profile/registration/code?phone='+this.state.phone.replace(/[^0-9]/g, "");
                }



            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })
    }

    componentDidMount() {
        // const data = {};
        //
        // axios({
        //
        //     url: GlobalVariable.host() + '/widget/profile',
        //     headers: {'Content-Type': 'application/json'},
        //     withCredentials: true,
        //     method: "POST",
        //     data: data,
        //     // receive two    parameter endpoint url ,form data
        // })
        //     .then(result => { // then print response status
        //         this.setState({
        //             auth: true,
        //             isLoad: true,
        //             name: result.data.name,
        //             phone: result.data.phone,
        //             record: result.data.record,
        //         })
        //
        //
        //     }).catch(error => {
        //     this.setState({auth: false, isLoad: true})
        //     // this.changeError(true)
        // })
    }

    renderButton() {
        if (this.state.password.length > 2 && this.state.isGoing && this.state.name.length > 2 && this.state.phone.length > 9) {
            return (
                <div onClick={() => this.createClientApi()} className={'button'}>
                    Регистрация
                </div>
            )
        } else {
            return (

                <div className={'button-false'}>
                    Регистрация
                </div>

            )
        }
    }

    render() {

        return (
            <div className={'block-body'} style={{maxWidth: '600px', margin: '0 auto'}}>
                <div className={'header'}>
                    <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile'}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Регистрация</div>
                        </div>
                    </Link>
                </div>
                <div className={'form'}>
                    <div className={'input'}>
                        <div className={'img'}><img
                            src={'/img/profile-logo.png'}/></div>
                        <input value={this.state.name} onChange={this.ChangeName} placeholder={'Ваше имя'}
                               className={'input-text'}/>
                    </div>
                    <div className={'input'}>
                        <div className={'img'}><img
                            src={'/img/phone-active.png'}/></div>
                        <div style={{position:"absolute", padding:'15px', paddingLeft:'50px'}} className={''}>+7</div>
                        <input style={{paddingLeft:'73px'}}  onChange={this.ChangePhone} value={this.state.phone} placeholder={'(xxx) xxx-xx-xx'}
                               className={'input-text'}/>
                    </div>
                    <div className={'input'}>
                        <div className={'img'}><img
                            src={'/img/unlock-active.png'}/>
                        </div>
                        <input value={this.state.password} onChange={this.ChangePassword} placeholder={'Пароль'}
                               className={'input-text'}/>
                    </div>
                    <div style={{paddingBottom:'100px'}}  className="form-check mt-2">

                        <div><input checked={this.state.isGoing}
                                    onChange={this.handleInputChange} name="isGoing"
                                    style={{paddingLeft: '15px', width: '15px'}} type="checkbox"
                                    className="form-check-input"
                                    value=""/></div>
                        <div style={{marginLeft: '20px', color: '#9E9E9E'}} className={'ml-4'}>Нажимая кнопку
                            «Регистрация» вы принимаете Пользовательское соглашение и
                            Политику конфиденциальности
                        </div>
                    </div>


                </div>
                {this.renderButton()}

                <div>{this.state.error}</div>

                <Menu active={'profile'} widgetId={this.props.widgetId}/>
            </div>


        )


    }
}

export default Registration;