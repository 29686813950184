import React, {Component} from 'react';
import GlobalVariable from "../../../GlobalVariable";
import {Link} from "react-router-dom";
import Menu from "../../Menu";
import axios from "axios";


export class DateTime extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: [],
            time: [],
            activeDateTime: [],
            activeTime: '',
            activeDate: '',
            currentDate: '',
            calendar: '',
            nameMonth: '',
            activeMonth: '',
            isLoadTime: false,
            isLoadCalendar: false,
            error: false,
            errorInfo: null,
        };    }


    getTimeSlot(date) {
        let urlAPI2 = GlobalVariable.host() + '/open/widget/booking/companies/available_time_slots?date=' + date;
        let service = sessionStorage.getItem('service')
        let staff = sessionStorage.getItem('staff')
        let activeService = {}
        let activeStaff = {}
        if (service) {
            activeService = JSON.parse(service)[this.props.idPoint]
        }
        if (staff) {
            activeStaff = JSON.parse(staff)[this.props.idPoint]

        }
        const data = {
            serviceIds: activeService,
            resourceId: activeStaff,
        };

        this.setState(
            {

                isLoadTime: false,

            });

        axios({

            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState(
                    {
                        time: result.data.availableTimeSlots,
                        isLoadTime: true,
                    error: false
                        ,
                        errorInfo: result.data.bad,

                    });


            }).catch(error => {
            this.setState({auth: false, isLoad: true, isLoadTime: false,error: true, time: []})
            // this.changeError(true)
        })

    }

    calendarChange(status) {
        let dateTime = sessionStorage.getItem('dateTime')
        if (dateTime) {
            if (JSON.parse(dateTime)[this.props.idPoint]) {
                this.setState({
                    activeDate: JSON.parse(dateTime)[this.props.idPoint].date,
                    activeTime: JSON.parse(dateTime)[this.props.idPoint].time,
                })
            }

        }
        // let urlAPI = GlobalVariable.host()+'/open/widget/booking/companies/' + this.props.idPoint+ '/available_dates';
        let urlAPI1 = GlobalVariable.host() + '/open/widget/booking/companies/' + this.props.idPoint + '/calendar';
        let service = sessionStorage.getItem('service')
        let staff = sessionStorage.getItem('staff')
        let activeService = {}
        let activeStaff = {}
        if (service) {
            activeService = JSON.parse(service)[this.props.idPoint]
        }
        if (staff) {
            activeStaff = JSON.parse(staff)[this.props.idPoint]

        }
        const data = {
            serviceIds: activeService,
            resourceId: activeStaff,
            status: status,
            currentDate: this.state.activeDate,
            activeMonth: this.state.activeMonth

        };


        axios({

            url: urlAPI1,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState(
                    {
                        calendar: result.data.calendar,
                        currentDate: result.data.currentDate,
                        activeDate: result.data.currentDate,
                        nameMonth: result.data.nameMonth,
                        activeMonth: result.data.activeMonth

                    });
                this.getTimeSlot(result.data.currentDate)

            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })
    }


    componentDidMount() {
        let dateTime = sessionStorage.getItem('dateTime')
        if (dateTime) {
            if (JSON.parse(dateTime)[this.props.idPoint]) {
                // console.log('090')
                this.setState({
                    activeDate: JSON.parse(dateTime)[this.props.idPoint].date,
                    activeTime: JSON.parse(dateTime)[this.props.idPoint].time,
                })
            }


        }
        // let urlAPI = GlobalVariable.host()+'/open/widget/booking/companies/' + this.props.idPoint+ '/available_dates';
        let urlAPI1 = GlobalVariable.host() + '/open/widget/booking/companies/' + this.props.idPoint + '/calendar';
        let service = sessionStorage.getItem('service')
        let staff = sessionStorage.getItem('staff')
        let activeService = {}
        let activeStaff = {}
        if (service) {
            activeService = JSON.parse(service)[this.props.idPoint]
        }
        if (staff) {
            activeStaff = JSON.parse(staff)[this.props.idPoint]

        }
        const data = {
            serviceIds: activeService,
            resourceId: activeStaff,
        };


        axios({

            url: urlAPI1,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                console.log(result.data)
                this.setState(
                    {
                        isLoadCalendar :true,
                        calendar: result.data.calendar,
                        currentDate: result.data.currentDate,
                        activeDate: result.data.currentDate,
                        nameMonth: result.data.nameMonth,
                        activeMonth: result.data.activeMonth,

                    });
                this.getTimeSlot(result.data.currentDate)

            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

    }

    renderDate() {
        let days = ['ВC', 'ПН', 'ВТ', 'СР',
            'ЧТ', 'ПТ', 'СБ'];
        const {date, time, currentDate, activeDateTime} = this.state;
        if (date.length == 0) {
            return (
                <div>
                    Нет доступных дат
                </div>
            )
        }
        return (
            <div className={'row'}>


                <h4 style={{
                    textAlign: "center",
                    marginBottom: '10px'
                }}>{this.props.company.name}-{this.props.company.address} </h4>
                <h4>Дата</h4>
                {date.map(items => (
                    <div className={'col-2 col-md-1 date_block_item'}
                         style={{borderColor: activeDateTime != items.date.date ? 'black' : 'red'}} onClick={() => {
                        this.getTimeSlot(items.date.date)
                    }}>
                        {days[items.date.dayNumber]}<br/>
                        {items.date.day}

                    </div>


                ))}

            </div>
        )
    }

    renderTime() {
        const {isLoadTime, time, currentDate, activeTime,error,errorInfo} = this.state;
        console.log(this.state.errorInfo)

        if (error) {
            return (
                <div>
                    Нет доступного времени
                </div>
            )
        }if (!isLoadTime) {
            return (
                <div>
                    Загрузка...
                </div>
            )
        }
        if(errorInfo!=null){
            return (
                <div>
                    {this.state.errorInfo}
                </div>
            )
        }
        if (time.length == 0) {
            return (
                <div>
                    Нет доступного времени
                </div>
            )
        }

        console.log(this.state.time)
        return (

            Object.entries(this.state.time).map(([k,v]) => (

                <div onClick={() => this.activeTime(k)}
                     className={activeTime == k ? 'col-2 col-md-1 date_block_item active' : 'col-2 col-md-1 date_block_item'}>
                    {k}
                </div>
            ))
        )
    }


    renderCalendar() {
        const {calendar, activeDate} = this.state;
        if(!this.state.isLoadCalendar){
return (<div style={{textAlign:'center'}}> Загружаем данные! Ожидайте</div>
    )
        }
        if (calendar.length > 0) {
            return (
                <div className={'calendar'}>
                    <div style={{justifyContent: 'space-between'}} className={'d-flex'}>
                        <div><h5>{this.state.nameMonth}</h5></div>
                        <div></div>

                        <div className={'d-flex'}>
                            <div style={{cursor: 'pointer'}} onClick={() => this.calendarChange('-1')}><img
                                className="mr-3" src="/img/arrowLeft.png"/></div>
                            <div style={{cursor: 'pointer'}} onClick={() => this.calendarChange('+1')}><img
                                className="mr-3" src="/img/arrowRight.png"/></div>
                        </div>
                    </div>
                    <div className={'d-flex'}>
                        <div className={'day'}>Пн</div>
                        <div className={'day'}>Вт</div>
                        <div className={'day'}>Ср</div>
                        <div className={'day'}>Чт</div>
                        <div className={'day'}>Пт</div>
                        <div className={'day'}>Сб</div>
                        <div className={'day'}>Вс</div>
                    </div>
                    <div style={{flexDirection: 'column'}} className={'d-flex'}>
                        {calendar.map((weeks) => (
                            <div className={'weeks'}>
                                {
                                    weeks.map((day) => (
                                        <div onClick={() => this.activeDate(day.date)} style={{cursor: "pointer"}}
                                             className={'day-num'}>
                                            {
                                                activeDate == day.date ?
                                                    <div className={'active'}>{day.day}</div> : <div
                                                        style={day.status ? {color: 'red'} : {color: 'black'}}> {day.day}</div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))}
                    </div>

                </div>
            )
        }

    }

    activeDate(date) {
        if (date != null) {
            this.setState({activeDate: date});
            // this.setState({time: []});
            this.getTimeSlot(date)
        }


    }

    activeTime(time) {
        let urlAPI = GlobalVariable.host() + '/open/widget/booking/companies/time_slots_change';

        const data = {
            date: this.state.activeDate,
            time: time,
        };


        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                // console.log(result.data)
                this.setState(
                    {


                    });

            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

        // console.log(time)
        this.setState({activeTime: time});
    }

    renderButton() {
        if (this.state.activeTime.length > 0 && this.state.activeDate.length > 0) {
            return (
                <Link style={{marginBottom:'40px'}}
                    to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint + ""}>
                    <div  className={'button'}>
                        Выбрать время
                    </div>
                </Link>

            )
        } else {
            return (
                <div style={{marginBottom:'40px'}} className={'button-false'}>
                    Выбрать время
                </div>
            )
        }
    }

    render() {


        const {date, time, currentDate, activeDateTime, activeTime} = this.state;
        // console.log(time)
        return (
            <div className={'block-body'}>
                <div className={'header'}>
                    <Link
                        to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Дата и время</div>
                        </div>
                    </Link>
                </div>

                {this.renderCalendar()}


                <div style={{paddingBottom:'100px'}} className={'body_block'}>
                    <div className={'row'}>

                        <div className={'pt-5 pb-3'}>
                            <h5>Время для записи:</h5>
                            <div className={'row date_block'}>
                                {this.renderTime()}


                            </div>
                        </div>

                        {this.renderButton()}
                        {/*{this.renderTime()}*/}


                    </div>
                </div>

                <Menu active={'record'} widgetId={this.props.widgetId}/>
            </div>
        )


    }
}

export default DateTime;