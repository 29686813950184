import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import List from "../CompanyList/List";
import MenuRecord from "../Record/MenuRecord";
import DateTime from "../Record/DateTime/DateTime";
import Service from "../Record/Service/Service";
import Staff from "../Record/Staff/Staff";
import Menu from "../Menu";
import Security from "../Security";
import axios from "axios";
import {Map, Placemark, YMaps} from "react-yandex-maps";

import GlobalVariable from "../../GlobalVariable";


export class CityPoint extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            cityName: 'Загрузка данных',
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            isLoad: false,
            auth: false,
            name: '',
            phone: '',
            companies: [],
            pointsYandexMap: [],

        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }
    updateMenu = (value) => {
        this.setState({activeBlock: value})
    }

    componentDidMount() {
        let utm = sessionStorage.getItem('utm');
        if(!utm){
            sessionStorage.setItem('utm', window.location.search);
        }
        console.log(utm)
        const queryParams = 'widget=' + this.props.widgetId;
        localStorage.setItem('activePoint', '');
        const data = {
            'link':'changeCompany'
        };

        let urlAPI = GlobalVariable.host() + '/open/widget/booking/companies/city/info/?idCity=' + this.props.idCity +'&' + queryParams;
        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    companies: result.data.points,
                    pointsYandexMap: result.data.pointsYandexMap,
                    cityName: result.data.points[0].city,
                })


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })




    }
   onMapClick(e){
       window.location.href = "/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + e
    }

    renderPoint() {
        if (this.state.companies.length == 0) {
            return (
                <div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '100%', height: '250px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '60%', height: '30px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '100%', height: '250px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '60%', height: '30px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '100%', height: '250px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '60%', height: '30px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '100%', height: '250px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '60%', height: '30px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>

                </div>
            )
        } else {
            return (
                this.state.companies.map((item) => (
                    <div className={' mt-4'}>
                        <Link
                            to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + item.id}>

                            {/*<div style={{*/}
                            {/*    backgroundImage:`url(${item.logo})`,*/}
                            {/*    backgroundSize:'cover',*/}
                            {/*    backgroundRepeat:'no-repeat',*/}
                            {/*    // background: "#D9D9D9",*/}
                            {/*    width: '100%',*/}
                            {/*    height: '250px',*/}
                            {/*    borderRadius: '10px'*/}
                            {/*}}></div>*/}
                            <div style={{cursor: "pointer", marginBottom: '10px'}} className={'city-point  mb-4'}>

                                <img className="mr-3" src="/img/pointMap.png"/>
                                <div>{item.address}</div>
                            </div>
                        </Link>

                    </div>
                ))
            )
        }
    }

    renderMap() {
        const {pointsYandexMap, companies} = this.state;
        if (this.state.companies.length != 0) {
            return (
                <div><YMaps>
                    <div>

                        <Map  width="100%" defaultState={{
                            center: [companies[0].coordinates.lat, companies[0].coordinates.lon],
                            zoom: 10
                        }}>
                            {
                                this.state.companies.map((item) => (
                                    <Placemark onClick={()=>this.onMapClick(item.id)} id = {item.id} geometry={[item.coordinates.lat, item.coordinates.lon]} properties={{
                                        hintContent: item.address,
                                        balloonContent: 'Парикмахеская Чио Чио',
                                        iconCaption: item.address
                                    }}/>
                                ))
                            }


                        </Map>
                    </div>
                </YMaps></div>
            )
        }
    }


    render() {

        return (
            <div className={'body'} id={'city-point'}>
                <Link to={"/" + this.props.widgetId + "/record/company-all"}>
                    <div style={{cursor: "pointer", marginBottom: '10px'}} className={'backStep d-flex'}>
                        <img className="mr-3" src="/img/arrow-left.svg"/>
                        <div><h2 className={'mb-0'} style={{marginLeft: '10px'}}>{this.state.cityName}</h2></div>
                    </div>
                </Link>
                <div className={'header'}>
                    <h2>Выберите парикмахерскую
                    </h2>


                </div>
                <div className={'point-block'}>
                    <div className={'point-item'} style={{paddingBottom: '50px'}}>
                        {/*<img/>*/}

                        {this.renderMap()}
                        {this.renderPoint()}

                    </div>

                </div>
                <Menu active={'record'} widgetId={this.props.widgetId}/>

            </div>

        )


    }
}

export default CityPoint;