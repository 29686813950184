import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import List from "../CompanyList/List";
import MenuRecord from "../Record/MenuRecord";
import DateTime from "../Record/DateTime/DateTime";
import Service from "../Record/Service/Service";
import Staff from "../Record/Staff/Staff";
import Menu from "../Menu";
import Security from "../Security";
import axios from "axios";

import GlobalVariable from "../../GlobalVariable";


export class RecordReady extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            isLoad: false,
            auth: false,
            name: '',
            phone: '',
            record: [],

        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }
    updateMenu = (value) => {
        this.setState({activeBlock: value})
    }

    componentDidMount() {
        const data = {};

        axios({

            url: GlobalVariable.host() + '/widget/profile',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    auth: true,
                    isLoad: true,
                    name: result.data.name,
                    phone: result.data.phone,
                    record: result.data.record,
                })


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })
    }


    render() {

        return (
            <div className={''} id={'point-item'}>
                <div className={'top-block'}>
                    <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                        <img className="mr-3" src="/img/arrow-left.svg"/>
                        <div style={{marginLeft: '10px'}}>Выберите...</div>
                    </div>
                    <div className={'header-block'}>
                        <div>
                            <div style={{justifyContent: 'space-between'}} className={'d-flex mb-2 '}>

                                <div style={{marginLeft: '10px'}}>Чио Чио на Ленина</div>
                                <div className={'stars'}><img style={{width: '20px'}} className="mr-3 stars-img"
                                                              src="/img/stars.png"/></div>


                            </div>
                            <div style={{cursor: "pointer", marginBottom: '10px'}} className={'city-point'}>
                                <img className="mr-3" src="/img/pointMap.png"/>
                                <div>Ул. Пушкина д. 123</div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className={'body-block'}>
                    <h2>Выбрано 2 услуги на 2 750₽</h2>
                    <div>
                        <div>Стрижка мужская</div>
                        <div>Маникюр</div>
                    </div>

                </div>
                <div className={'button'}>
                    Записаться
                </div>
                <Menu active={'record'} widgetId={this.props.widgetId}/>
            </div>

        )


    }
}

export default RecordReady;