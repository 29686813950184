import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import List from "../CompanyList/List";
import MenuRecord from "../Record/MenuRecord";
import DateTime from "../Record/DateTime/DateTime";
import Service from "../Record/Service/Service";
import Staff from "../Record/Staff/Staff";
import Menu from "../Menu";
import Security from "../Security";
import axios from "axios";

import GlobalVariable from "../../GlobalVariable";


export class About extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            isLoad: false,
            auth: false,
            name: '',
            phone: '',
            record: [],

        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }


    render() {

        return (
            <div className={'block-body'}>
                <div className={'header'}>
                    <Link to={''}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            {/*<div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>*/}

                            <div className={'title'}>О нас</div>
                        </div>
                    </Link>
                </div>
                {/*<div style={{margin: '0 auto'}} className={'row col-10'}>*/}
                {/*    <div className={'col-4'}>*/}
                {/*        Информация*/}
                {/*    </div>*/}
                {/*    <div className={'col-4'}>*/}
                {/*        Отзывы*/}
                {/*    </div>*/}
                {/*    <div className={'col-4'}>*/}
                {/*        Контакты*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div style={{height: '100%', paddingBottom:'200px'}}>
                    <div className={'about-img col-12'}></div>
                    <div>

                        <h3>Мы любим и ценим наших клиентов</h3>
                        <p>Поэтому мы прикладываем максимум усилий, чтобы клиенту было комфортно.</p>
                        <h5>У нас:</h5>
                        <p>Высокие стандарты качества обслуживания</p>
                        <p>Японская технология воздушного мытья головы</p>
                        <p>Конкурентные цены</p>
                        <h3>Чио Чио. Всегда рядом.</h3>

                    </div>
                </div>
                <Menu active={'about'} widgetId={this.props.widgetId}/>
            </div>


        )


    }
}

export default About;