import React, {Component} from 'react';
import {
    Link,
    useParams,
} from "react-router-dom";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import GlobalVariable from "../../GlobalVariable";
import Menu from "../Menu";
import axios from "axios";

export class List extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            companies: [],
            fullCompanies: [],
            ActiveCompany: [],
            formCity: '',

        };

        this.handleChange = this.handleChange.bind(this);
    }

    updateMenu = (value) => {
        this.setState({activeBlock: value})
    }

    sortCompany(array) {
        let newArray = [];
        for (const key in array) {

            for (const key2 in array[key]) {

            }
        }


    }

    saveCompany(id) {
        localStorage.setItem('activeCompanyCity', id);
    }

    saveCity(id) {
        localStorage.setItem('activeCity', id);
    }

    handleChange(event) {
        let string = event.target.value;
        if (string == '') {
            this.setState({
                formCity: string,
                companies: this.state.fullCompanies
            });
        } else {
            let string_ = string[0].toUpperCase() + string.slice(1);
            string = string_
            if (this.state.fullCompanies[string[0]]) {
                let obj = {};
                if (string.length == 1) {
                    obj = {[string[0]]: this.state.fullCompanies[string[0]]}
                    this.setState({
                        formCity: string,
                        companies: obj
                    });

                }
                if (string.length > 1) {

                    let arr = {};
                    for (var key in this.state.companies[string[0]]) {
                        let string2 = string.toLowerCase()
                        let nameCity = key.toLowerCase()
                        if (nameCity.substr(0, string2.length) == string2) {

                            arr[key] = this.state.companies[string[0]][key]
                        }

                    }

                    this.setState({
                        formCity: string,
                        companies: obj = {[string[0]]: arr}
                    });
                }


            } else {
                this.setState({
                    formCity: string,
                    companies: []
                });
            }
        }



    }

    componentDidMount() {
        let utm = sessionStorage.getItem('utm');
        if(!utm){
            sessionStorage.setItem('utm', window.location.search);
        }



        const queryParams = 'widget=' + this.props.widgetId;
        // const queryParams = new URLSearchParams(window.location.search);

        axios({

            url: GlobalVariable.host() +  '/open/widget/booking/companies/allcity/info/?' + queryParams,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: {},
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    companies: result.data.companies,
                    fullCompanies: result.data.companies
                })


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

    }

    alphabetStatusLetter() {
        let alphabet = [
            ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й'],
            ['К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У'],
            ['Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'],
        ]

        return (
            alphabet.map((item) => (
                <div className={'d-flex'} style={{justifyContent: "space-between"}}>
                    {
                        item.map((letter) => (
                            <a className={this.state.companies[letter] ? "letter  active" : "letter"}
                               href={"#" + letter}>{letter}</a>
                        ))
                    }
                </div>
            )))
    }

    renderPoint() {
        let companies = this.state.companies;
        if (companies.length == 0 && this.state.fullCompanies.length == 0) {
            return (
                <div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>


                </div>
            )
        } else if (companies.length == 0) {
            return (
                <div>Не найдено</div>
            )
        } else {
            return (
                Object.keys(companies).map((keyName, i) => (
                    <div>
                        <div id={keyName} className={'letter'}>{keyName}</div>
                        <div>
                            {Object.keys(companies[keyName]).map((keyName2, i) => (
                                <div>
                                    <Link
                                        to={"/" + this.props.widgetId + "/record/company-city/" + keyName2.replace(/[^\d;]/g, '')}>
                                        <div style={{cursor: "pointer", marginBottom: '20px'}}
                                             className={'cityName'}>{keyName2.replace(keyName2.replace(/[^\d;]/g, ''), '')}
                                        </div>
                                    </Link>
                                    <div>
                                        {Object.keys(companies[keyName][keyName2]).map((keyName3, i3) => (
                                            <Link
                                                to={"/" + this.props.widgetId + "/record/company-city/" + keyName2.replace(/[^\d;]/g, '') + "/point/" + companies[keyName][keyName2][keyName3].id}>
                                                <div style={{cursor: "pointer", marginBottom: '20px'}}
                                                     className={'city-point'}>
                                                    <img className="mr-3" src="/img/pointMap.png"/>
                                                    <div>{companies[keyName][keyName2][keyName3].address}</div>
                                                </div>
                                            </Link>
                                        ))}

                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                ))
            )
        }

    }

    render() {
        let companies = this.state.companies;


        return (
            <div style={{maxWidth: '768px'}} className={"container-sm"}>


                <div>
                    <div className={'header_block'}>


                        <div className={'header_center_title d-flex'}>
                            <div style={{verticalAlign: "middle"}}>

                            </div>

                            <div style={{width: '90%'}} className="form-group">

                                <h2 className={'mb-4 mt-4'}>Выберите город</h2>
                                <div>
                                    <div style={{position:"absolute", padding:"12px"}}><img src="/img/location.png"/></div>
                                    <input style={{paddingLeft:"10px"}} value={this.state.formCity} onChange={this.handleChange} type="email"
                                           className="form-control search" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="Выберите город"/>
                                </div>


                            </div>

                            <div className={'alphabet'}>
                                {this.alphabetStatusLetter()}

                            </div>
                        </div>

                    </div>
                    <div className={'body_block'}>
                        <div style={{marginTop: '35px', marginBottom:'100px'}} className={'row'}>
                            {
                                this.renderPoint()
                            }



                        </div>
                    </div>


                </div>

                <Menu active={'record'} widgetId={this.props.widgetId}/>
            </div>
        )


    }
}

export default List;