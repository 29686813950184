import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import List from "../CompanyList/List";
import MenuRecord from "../Record/MenuRecord";
import DateTime from "../Record/DateTime/DateTime";
import Service from "../Record/Service/Service";
import Staff from "../Record/Staff/Staff";
import Menu from "../Menu";
import Security from "../Security";
import axios from "axios";
import InfoRecord from "./InfoRecord";
import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";


export class MyRecord extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            recordsPrev: [],
            recordsNext: [],
            isLoad: false,
            auth: true,
            activeRecord: '',

        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateService = (value) => {
        this.setState({service: value, activeBlock: 'menu'})
    }
    updateStaff = (value) => {
        this.setState({staff: value, activeBlock: 'menu'})
    }
    updateDateTime = (value) => {
        this.setState({dateTime: value, activeBlock: 'menu'})
    }
    updateCompany = (value) => {
        this.setState({activeCompany: value, activeBlock: 'menu'})
    }

    componentDidMount() {

        const data = {};

        axios({

            url: GlobalVariable.host() + '/widget/profile/my-record',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    recordsPrev: result.data.prevRecord,
                    recordsNext: result.data.nextRecord,
                    isLoad: true,
                    auth: true
                })
            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            window.location.href = '/' + this.props.widgetId + '/profile';
        })
    }
    deleteRecord(idRecord){
        // console.log(idRecord)
        const data = {
           idRecord:idRecord
        };

        axios({

            url: GlobalVariable.host() + '/widget/profile/record/delete',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.componentDidMount()
                this.setState({

                })
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })
    }
    // changeBlock() {
    //     let auth = this.state.auth
    //     if (!this.state.isLoad) {
    //         return (<div className="d-flex justify-content-center">
    //             <div className="spinner-grow text-danger" role="status">
    //                 <span className="sr-only">Loading...</span>
    //             </div>
    //         </div>)
    //     }
    //     switch (auth) {
    //         case true:
    //             return <InfoRecord></InfoRecord>;
    //         case false:
    //             return <Security/>;
    //         default:
    //             return <Order/>;
    //     }
    //
    //
    // }

    renderNextRecord(item) {
        if (this.state.activeRecord == item.id) {
            return (
                <tr onClick={() => this.setState({activeRecord: item.id==this.state.activeRecord?'':item.id})} style={{cursor: 'pointer', height: '100px'}}>
                    <th>{item.date} {item.time}</th>
                    <td>{item['service'].map((itemService) => (
                        <div>{itemService.serviceName}</div>
                    ))}</td>
                    <td className={'d-flex'}>{item.paid}&nbsp;₽
                        <div onClick={()=>this.deleteRecord(item.id)} style={{backgroundColor: 'red', height: '70px', width: '100px', margin:'5px', borderRadius:'15px', marginLeft:'15px'}}>

                                <div style={{paddingTop:'10px'}} >
                                    <div style={{margin:'0 auto', width:'fit-content'}}><img src={'/img/Trush_Icon_UIA.png'}/></div>
                                    <div style={{margin:'0 auto',color:'white', width:'fit-content'}}>Отменить</div>
                                </div>

                        </div></td>

                </tr>

            )
        } else {
            return (
                <tr onClick={() => this.setState({activeRecord: item.id})} style={{cursor: 'pointer'}}>
                    <th>{item.date} {item.time}</th>
                    <td>{item['service'].map((itemService) => (
                        <div>{itemService.serviceName}</div>
                    ))}</td>
                    <td>{item.paid} ₽</td>

                </tr>
            )
        }
    }

    render() {
        if (!this.state.isLoad) {
            return (
                <div id={'start-profile'}>
                    <div className={'logo_block'}>
                        <div className={'logo'}>

                        </div>

                    </div>
                    <div className={'header'}>
                        <h2>Рады видеть вас!
                        </h2>
                        <h2>Загружаем данные, пожалуйста подождите</h2>

                    </div>


                    <Menu active={'my-record'}/>
                </div>
            )
        }
        return (
            <div className={'block-body'}>
                <div className={'header'}>
                    <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile'}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Мои записи</div>
                        </div>
                    </Link>
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Дата</th>
                        <th scope="col">Услуги</th>
                        <th scope="col">Стоимость</th>

                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.recordsNext.map((item) => (

                            this.renderNextRecord(item)
                        ))
                    }
                    {
                        this.state.recordsPrev.map((item) => (
                            <tr
                                className={'prevRecord'}>
                                <th>{item.date} {item.time}</th>
                                <td>{item['service'].map((itemService) => (
                                    <div>{itemService.serviceName}</div>
                                ))}</td>
                                <td>{item.paid} ₽</td>

                            </tr>
                        ))
                    }

                    </tbody>
                </table>
                <div className={'d-flex'}>
                    <div><img style={{marginBottom: '3px'}} className="mr-3"
                              src={"/img/Ellipse1.png"}/></div>
                    <div style={{color: '#0D263F', marginLeft: '10px'}}>Будущая запись</div>
                </div>
                <div className={'d-flex'}>
                    <div><img style={{marginBottom: '3px'}} className="mr-3"
                              src={"/img/Ellipse2.png"}/></div>
                    <div style={{color: '#939393', marginLeft: '10px'}}>Прошлая запись</div>
                </div>
                <Link to={'record'}>
                    <div className={'button mt-5'}>
                        Новая запись
                    </div>
                </Link>


                <Menu active={'my-record'} widgetId={this.props.widgetId}/>
            </div>

        )


    }
}

export default MyRecord;