import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import axios from "axios";

import GlobalVariable from "../../../GlobalVariable";
import Menu from "../../Menu";

export class RegistrationCode extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            isLoad: false,
            auth: false,
            name: '',
            code: '',
            code1: '',
            code2: '',
            code3: '',
            code4: '',


            record: [],

        };

        this.changeCode = this.changeCode.bind(this);
        this.changeCode1 = this.changeCode1.bind(this);
        this.changeCode2 = this.changeCode2.bind(this);
        this.changeCode3 = this.changeCode3.bind(this);
        this.changeCode4 = this.changeCode4.bind(this);
    }

    changeCode(event) {
        this.setState({code: event.target.value});
    }

    changeCode1(event) {

        this.setState({code1: event.target.value});
    }

    changeCode2(event) {
        this.setState({code2: event.target.value});
    }

    changeCode3(event) {
        this.setState({code3: event.target.value});
    }

    changeCode4(event) {
        this.setState({code4: event.target.value});
    }


    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }


    uploadCode() {
        // console.log(this.state)

        const data = {
            code: this.state.code1.concat(this.state.code2, '', this.state.code3,'', this.state.code4),
            phone:sessionStorage.getItem('regPhone')
        };

        axios({
            url: GlobalVariable.host() + '/open/widget/security/check/code',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                if (result.data.status == 'bad') {
                    this.setState({
                        auth: false,
                        // isLoad: true,

                    })
                } else {
                    this.setState({
                        auth: true,
                        // isLoad: true,
                        // name: result.data.name,
                        // phone: result.data.phone,
                        // record: result.data.record,
                    })

                    window.location.href = '/' + this.props.widgetId + '/profile';
                    // return <Redirect to={'/' + this.props.widgetId + '/profile/login/code'}></Redirect>
                }


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })
    }

    renderButton() {
        if (this.state.code1 && this.state.code2 && this.state.code3 && this.state.code4) {
            return (

                    <div onClick={()=>this.uploadCode()} className={'button'}>
                        Войти
                    </div>


            )
        } else {
            return (
                <div className={'button-false'}>
                    Войти
                </div>
            )
        }
    }


    render() {

        // return (
        //     <div id={'login_code'}>
        //         <div className={'header_block'}>
        //             <h2>Вход с кодом</h2>
        //         </div>
        //         <p>Код был отправлен по номеру {localStorage.getItem('phone')}</p>
        //         <div className={'input_block'}>
        //             <div className="form-group">
        //                 <label htmlFor="exampleFormControlInput1">Введите номер телефона</label>
        //                 <input onChange={this.changeCode} value={this.state.code} type="text" className="form-control"
        //                        placeholder='* * * *'/>
        //             </div>
        //         </div>
        //         <div className={'checkbox'}>
        //
        //
        //         </div>
        //         <div onClick={()=>this.uploadCode()} className={'button'}>
        //             Получить Код
        //         </div>
        //         <div className={'info'}>
        //             или вы можете войти при помощи Код-пароля
        //         </div>
        //         <Menu />
        //
        //     </div>
        //
        // )
        let array = [1, 2, 3, 4]
        return (
            <div id={'login_code'} className={'block-body'} style={{maxWidth: '600px', margin: '0 auto'}}>
                <div className={'header'}>
                    <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile/login/code/phone'}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Регистрация</div>
                        </div>
                    </Link>
                </div>
                <div className={'mt-5 mb-5'}>
                    Код был отправлен по номеру телефона
                </div>
                <div className={'form'}>

                    <div>
                        <form className={'input-code-block'}>

                            <input onKeyUp={this.changeCode1} style={{}} type={'text'} name={'inp'} data-start={'0'}
                                   data-len="1" size={1} placeholder={'X'}
                                   className={'input-code'}/>
                            <input onKeyUp={this.changeCode2} style={{}} type={'text'} name={'inp'} data-start={'1'}
                                   data-len="1" size={1} placeholder={'X'}
                                   className={'input-code'}/>
                            <input onKeyUp={this.changeCode3} style={{}} type={'text'} name={'inp'} data-start={'2'}
                                   data-len="1" size={1} placeholder={'X'}
                                   className={'input-code'}/>
                            <input onKeyUp={this.changeCode4} style={{}} type={'text'} name={'inp'} data-start={'3'}
                                   data-len="1" size={1} placeholder={'X'}
                                   className={'input-code'}/>
                        </form>

                    </div>


                </div>
                <div className={'mt-5 mb-5'}>
                    Для завершения операции введите код, который продиктует робот из входящего звонка.
                </div>
                {this.renderButton()}
                <div className={'mt-5 mb-5'}>
                    Отправить код еще раз <br/> через 60 секунд
                </div>
                <Menu active={'profile'} widgetId={this.props.widgetId}/>
            </div>


        )


    }
}

export default RegistrationCode;