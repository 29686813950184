import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Menu from "../../Menu";
import axios from "axios";
import GlobalVariable from "../../../GlobalVariable";


export class ChangeProfile extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            isLoad: false,
            auth: false,
            name: null,
            phone: null,
            date: null,
            email: null,
            record: [],
            validEmail: '',
            error: null,

        };
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name == 'date') {
            var now = new Date();
            // console.log(now.toISOString().split('T')[0] )
            // console.log(value )
            if (value < now.toISOString().split('T')[0]) {
                this.setState({
                    date: value
                });
            }

        }
        else if (name == 'phone') {
            let val = value;
            val = val.replace(/[^+\d]/g, '');


            let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

            num = num.trim();

            if (num.length <= 12) {
                this.setState({phone: num});
            }
        }
        else if (name == 'email') {
            if( this.validateEmail(value)){
                this.setState({
                    validEmail: true,
                    email: value
                });
            }
            else {
                this.setState({
                    validEmail: false
                });
            }

        }
        else {
            this.setState({
                [name]: value
            });
        }

    }

    validateEmail(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    componentDidMount() {
        const data = {};

        axios({

            url: GlobalVariable.host() + '/widget/profile',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                let email = result.data.email
                if(!email){
                    email = '';

                }
                let validEmail = this.validateEmail(email)?true:false
// console.log(validEmail)
// console.log('0980')
                this.setState({
                    auth: true,
                    isLoad: true,
                    name: result.data.name,
                    phone: '+' + result.data.phone,
                    record: result.data.record,
                    date: result.data.date,
                    email: result.data.email,
                    validEmail: validEmail,

                })


            }).catch(error => {
            // this.setState({auth: false, isLoad: true})
            // window.location.href = '/' + this.props.widgetId + '/profile';
        })
    }

    saveProfile() {
        const data = {
            name: this.state.name,
            phone: this.state.phone.replace(/[^0-9]/g, ""),

            date: this.state.date,
            email: this.state.email,
        };

        axios({

            url: GlobalVariable.host() + '/widget/profile/update',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    name: result.data.name,
                    phone: result.data.phone,
                    record: result.data.record,
                    date: result.data.date,
                    email: result.data.email,
                    error:false
                })


            }).catch(error => {
            this.setState({auth: false, isLoad: true, error:true})
            window.location.href = '/' + this.props.widgetId + '/profile';
        })
    }
    renderError(){
        if(this.state.error == null){
            return(
                ''
            )
        }
        else if(this.state.error == false){
            return(
                <div style={{backgroundColor: 'whitesmoke', borderColor:'whitesmoke',width:'250px', position:'absolute', bottom:'65px', right:'20px', color:"#0D263F"}} className="alert alert-success alert-dismissible fade show" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Закрыть">
                        <span aria-hidden="true">&times;</span></button>
                     Данные сохранены
                </div>
            )
        }
        else if(this.state.error == true){
            return(
                <div style={{backgroundColor: 'whitesmoke', borderColor:'whitesmoke',width:'250px', position:'absolute', bottom:'65px', right:'20px', color:"#0D263F"}} className="alert alert-success alert-dismissible fade show" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Закрыть">
                        <span aria-hidden="true">&times;</span></button>
                    Ошибка
                </div>
            )
        }

    }


    render() {
        if (this.state.isLoad) {
            return (
                <div className={'block-body'}>
                    <div className={'header'}>
                        <Link to={'/bf9eee80e13ce87a2d9d333ef76cf0b2/profile'}>
                            <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                                <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                                <div className={'title'}>Редактировать профиль</div>
                            </div>
                        </Link>
                    </div>
                    <div className={'form'}>
                        <div className={'input'}>
                            <div className={'img'}><img src="/img/profile-logo.png"/></div>
                            <input name="name" onChange={this.handleInputChange} value={this.state.name}
                                   placeholder={'Ваше имя'} className={'input-text'}/>
                        </div>
                        <div className={'input'}>
                            <div className={'img'}><img src="/img/calendar.png"/></div>
                            <input name="date" onChange={this.handleInputChange} type={'date'} value={this.state.date}
                                   placeholder={'Ваш день рождения'} className={'input-text'}/>
                        </div>
                        <div className={'input'}>
                            <div className={'img'}><img src="/img/call.png"/></div>
                            <input disabled={true} name="phone" onChange={this.handleInputChange} value={this.state.phone}
                                   className={'input-text'}/>
                        </div>
                        <div className={'input'}>
                            <div className={'img'}><img src="/img/sms.png"/></div>
                            <input name="email" type={'email'} onChange={this.handleInputChange}
                                   value={this.state.email}
                                   placeholder={'Ваш email'} className={'input-text'}/>
                        </div>
                        <div className={'input'}>
                            <div className={'img'}><img src="/img/location.svg"/></div>
                            <input value={'Россия'} className={'input-text'}/>
                        </div>

                    </div>
                    <div  onClick={() => this.saveProfile()} className={'button'}>
                        Сохранить
                    </div>
                    <div style={{ paddingBottom:'100px'}}></div>
                    {this.renderError()}
                    <Menu active={'profile'} widgetId={this.props.widgetId}/>
                </div>


            )
        } else {
            return (
                <div id={'start-profile'}>
                    <div className={'logo_block'}>
                        <div className={'logo'}>

                        </div>

                    </div>
                    <div className={'header'}>
                        <h2>Рады видеть вас!
                        </h2>
                        <h2>Загружаем данные, пожалуйста подождите</h2>

                    </div>


                    <Menu active={'profile'}/>
                </div>
            )
        }


    }
}

export default ChangeProfile;