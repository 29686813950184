import React, {Component} from 'react';
import GlobalVariable from "../../../GlobalVariable";
import {Link} from "react-router-dom";
import axios from "axios";
import Menu from "../../Menu";


export class Service extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            services: [],
            serviceIsLoad: true,
            error: false,
            activeService: [],
            errorInfo: '',
            activeFullService: null,
        };


    }


    componentDidMount() {
        let urlAPI = GlobalVariable.host() + '/open/widget/booking/companies/services/';


        const data = {}
        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState(
                    {
                        services: result.data.services,
                        activeService: result.data.activeService,

                        serviceIsLoad: false,

                    });


            }).catch(error => {
            this.setState({auth: false, isLoad: true, error: true})
            // this.changeError(true)
        })


    }

    changeActiveFullService(service) {
        let activeFullService = this.state.activeFullService
        if (activeFullService == service.id) {
            this.setState({
                activeFullService: null
            });
        } else {
            this.setState({
                activeFullService: service.id
            });
        }


    }

    changeActiveService(service, type) {
        let activeService = this.state.activeService


        let urlAPI = GlobalVariable.host() + '/open/widget/booking/companies/services/change';


        const data = {
            idService: service.id

        }
        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState(
                    {
                        activeService: result.data.activeService,

                    });


            }).catch(error => {
            this.setState({auth: false, isLoad: true, error: true})
            // this.changeError(true)
        })
        // this.setState({
        //     activeService: [service]
        // });
        if (activeService) {
            let indexService: number[] = [];
            for (let i = 0; i < activeService.length; i++) {
                if (activeService[i].id == service.id) {
                    indexService.push(i)
                }

            }


            if (indexService.length == 0) {

                this.setState({
                    activeService: activeService.concat(service)
                });

            }


            if (indexService.length > 0) {
                // console.log(indexService)
                let result = activeService.splice(indexService[0], 1)
                //  console.log(result )
                this.setState({
                    activeService: activeService
                });
            }
        }


    }

    renderService(itemService) {
        let activeService = this.state.activeService;
        if (!activeService) {
            activeService = [];
        }
        sessionStorage.setItem('service', JSON.stringify({[this.props.idPoint]: activeService}));
        // console.log(this.state.serviceIsLoad)
        if (this.state.serviceIsLoad) {
            return (
                <div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>


                </div>
            )

        }
        let indexService: number[] = [];
        // console.log(activeService)
        for (let i = 0; i < activeService.length; i++) {
            if (activeService[i].id == itemService.id) {
                indexService.push(i)
            }

        }

        // if (indexService.length == 0) {
        //     return (
        //         <div onClick={() => {
        //             this.changeActiveService(itemService, 'add')
        //         }} className={'col-2 col-sm-2 row'}>
        //             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" fill="rgba(120, 120, 128, 0.16)"
        //                  className="bi bi-toggle-off" viewBox="0 0 16 16">
        //                 <path
        //                     d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/>
        //             </svg>
        //         </div>
        //     )
        // } else {
        //     return (
        //         <div onClick={() => {
        //             this.changeActiveService(itemService, 'add')
        //         }} className={'col-2 col-sm-2 row'}>
        //             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" fill="#E50040"
        //                  className="bi bi-toggle-on" viewBox="0 0 16 16">
        //                 <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
        //             </svg>
        //         </div>
        //     )
        // }

    }

    renderButtonService(itemService) {
        let activeService = this.state.activeService;
        if (!activeService) {
            activeService = [];
        }
        sessionStorage.setItem('service', JSON.stringify({[this.props.idPoint]: activeService}));
        // console.log(this.state.serviceIsLoad)

        let indexService: number[] = [];
        // console.log(activeService)
        for (let i = 0; i < activeService.length; i++) {
            if (activeService[i].id == itemService.id) {
                indexService.push(i)
            }

        }
        if(itemService.status ==false){
            return ('')
        }

        if (indexService.length == 0) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="48" viewBox="0 0 40 48" fill="none">
                    <rect y="4.00101" width="40" height="40" rx="20" fill="#F0F0F0"></rect>
                    <path d="M20 18.001V30.001" stroke="#060F07" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M26 24.001H14" stroke="#060F07" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
                // <div onClick={() => this.changeActiveService(itemService, 'add')}
                //          style={{cursor: 'pointer'}} className={'button-chio  '}>
                //         Выбрать услугу
                //
                //     </div>
            )
        } else {
            // return (
            //     <div onClick={() => this.changeActiveService(itemService, 'add')}
            //          style={{cursor: 'pointer'}} className={'button-chio-active  '}>
            //         Выбрано
            //
            //     </div>
            // )
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="48" viewBox="0 0 40 48" fill="none">
                    <path d="M26 24.0005H14" stroke="#060F07" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <rect x="1" y="5.0005" width="38" height="38" rx="19" stroke="#E8E8E8" stroke-width="2"></rect>
                </svg>
            )
        }

    }


    render() {

        let services = this.state.services;
        // console.log(services)

        return (
            <div id={'staff-block'} className={'block-body'}>
                <div className={'header'}>
                    <Link
                        to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint + ""}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <div className={'back-img'}><img className="mr-3" src="/img/arrow-left.svg"/></div>

                            <div className={'title'}>Услуги</div>
                        </div>
                    </Link>


                </div>


                <div className={'body_block'}>
                    <div style={{marginTop: '20px'}} className={'row'}>
                        {

                            this.state.serviceIsLoad ?
                                <div>
                                    <div className={'mt-5 mb-5'}>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '70px', height: '20px'}}></div>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '300px', height: '20px'}}></div>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '300px', height: '20px'}}></div>
                                    </div>
                                    <div className={'mt-5 mb-5'}>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '70px', height: '20px'}}></div>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '300px', height: '20px'}}></div>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '300px', height: '20px'}}></div>
                                    </div>
                                    <div className={'mt-5 mb-5'}>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '70px', height: '20px'}}></div>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '300px', height: '20px'}}></div>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '300px', height: '20px'}}></div>
                                    </div>
                                    <div className={'mt-5 mb-5'}>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '70px', height: '20px'}}></div>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '300px', height: '20px'}}></div>
                                        <div className={'mt-2 mb-2 preloader-gradient'}
                                             style={{width: '300px', height: '20px'}}></div>
                                    </div>


                                </div> : this.state.error ? <h5>Нет услуг под Ваши критерии...</h5> : ""


                        }


                        <div style={{marginBottom:'150px'}}>
                            {
                                services.map(itemGroup => (
                                    <div className={'row'}>

                                        <div onClick={() => {
                                            this.changeActiveService(itemGroup, 'add')
                                        }} style={{flexDirection: 'row', justifyContent: 'space-between'}}
                                             className={' d-flex serviceTitle'}>
                                            <div className={'col-6'} style={{fontWeight: 700}}>{itemGroup.titleGroup}</div>

                                        </div>
                                        <div className={'  mt-1 mb-2 11'}>
                                            <div>
                                                {itemGroup.servicesList.map((itemService) =>(

                                                    <div key={itemService.id} className={'col-12 row service-item'}
                                                         onClick={() => this.changeActiveService(itemService, 'add')}
                                                         style={{cursor: "pointer"}}
                                                    >

                                                        <div
                                                            className={'col-12  d-flex serviceList'}>
                                                            <div className={'d-flex'} style={{
                                                                flexDirection: "row",
                                                                justifyContent: 'space-between',
                                                                width: '100%'
                                                            }}>
                                                                <div>
                                                                    <div style={{fontWeight: 700}}>{itemService.title}</div>

                                                                    <div
                                                                        style={{
                                                                            fontWeight: 600,
                                                                            color: '#e50040'
                                                                        }}>{itemService.price} рублей
                                                                    </div>
                                                                    <div style={{fontSize:"small"}}>Приблизительное время выполнения услуги: {itemService.length} минут</div>
                                                                    {itemService.status?'':<hr/>}
                                                                    {itemService.status?'':<div style={{fontSize:"small", color:'red'}}>Измените время или выбирете другого мастера</div>}
                                                                </div>
                                                                <div className={'btn_wrapper'}>
                                                                    <div>
                                                                        {this.renderButtonService(itemService)}


                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>



                                                    </div>
                                                    )


                                                )}


                                            </div>

                                            {/*<div onClick={() => this.changeActiveFullService(items)}*/}
                                            {/*     style={{cursor: 'pointer', textAlign:'center', textDecoration:'underline dotted',textUnderlineOffset: '4px'}} className={' '}>*/}
                                            {/*    {this.state.activeFullService==items.id?"Скрыть все услуги":"Показать все услуги"}*/}

                                            {/*</div>*/}


                                        </div>
                                        {/*<hr/>*/}

                                    </div>
                                ))
                            }
                        </div>

                    </div>

                    <Link
                        to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint + ""}
                        style={{position:'fixed', bottom: '100px', width:'100%', left:'0'}}>
                        <div style={{display:'flex', justifyContent:'center'}}> <button  type="button" className="btn btn-secondary btn-service">Продолжить
                        </button></div>

                    </Link>

                </div>
                <Menu active={'record'}/>

            </div>
        )


    }
}

export default Service;